import React from "react";
import Seo from "../components/seo/index";
import Layout from "../layout/index";

import BasicCompanyFormRegister from "../components/sharedComponents/Basic-form/basicCompanyFormRegister";

export default function BasicFormpage() {
  return (
    <>
      <div>
        <Seo title="Green Heartfulness Run 2024 - New Registration" />
        <Layout isSticky>
        <div>
         <BasicCompanyFormRegister />
        </div>
        </Layout>
      </div>
    </>
  );
}
