import React, { useState } from "react";
import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import { Controller, useForm } from "react-hook-form";
import {
  bloodGroup,
  genderList,
  bibLocation,
  tShirtSizes,
  dateconvert,
  accommodation,
  ageCalculator,
  datecalendarconvert,
} from "../../../assets/data/commonData";
import PhoneInput from "react-phone-input-2";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { navigate } from "gatsby";
import { useEffect } from "react";
import KanhaRunService from "../../services/kanha.service";
import { toast, ToastContainer } from "react-toastify";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../assets/config";
import { lStorage } from "../../utils/storage";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { CreateImageFromInitials, GetRandomColor } from "../../utils/common";
import phonesmallicon from "../../../assets/images/successfulcards/phone-small-icon.png";
// import mapsmallicon from "../../../assets/images/successfulcards/map-small-icon.png";
import envelope from "../../../assets/images/successfulcards/envelope-fill.svg";

export default function BasicFormRegister() {
  const datePickerId = new Date().toISOString().slice(0, 10);
  const minDateValue = "1950-01-06";
  const skuList = lStorage.get("skuData");
  const [formData, setFormData] = useState([]);
  const [accommidationStatus, setAccommidationStatus] = useState("");
  const [raceList, setRaceList] = useState([]);
  const [raceError, setRaceError] = useState(false);
  const [sameNumberError, setSameNumber] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [emCountryCode, setEmCountryCode] = useState();
  const [showBib, setShowBib] = useState("");
  const [isContactValid, setIsContactValid] = useState();
  const [isEcontactValid, setIsEcontactValid] = useState();
  const [countryCode, setCountryCode] = useState("91");
  const [isParticipated, setIsParticipated] = useState(false);
  const [participantData, setParticipantData] = useState([]);

  const kanhaApi = new KanhaRunService();
  const CompanyRegData = lStorage.get("companyRegData");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const prevData = [];

    const datass = {
      ...data,
      ...(countryCode && { countrycode: countryCode }),
      ...(emCountryCode && { em_country_code: emCountryCode }),
    };

    let split = data.race_category_id.split(",");
    let v1 = split[0];
    let v2 = split[1];
    let v3 = split[2];
    const years = ageCalculator(data.dob);
    let valid = "";
    if (v1 <= 4) {
      valid =
        years < 8 && v1 == 1
          ? "valid"
          : years >= 8 && (v1 == 2 || v1 == 1)
          ? "valid"
          : years >= 15 && (v1 == 3 || v1 == 2 || v1 == 1)
          ? "valid"
          : years >= 18 && (v1 == 4 || v1 == 3 || v1 == 2 || v1 == 1)
          ? "valid"
          : "notvalid";
    } else {
      valid = "valid";
    }
    let mobileValid =
      data.contact_number !== data.emergence_contact_number
        ? "valid"
        : "mobile number same";
    if (valid === "valid" && mobileValid === "valid") {
      setAccommidationStatus("");
      const birdValues =
        raceList.earlybird == "YES"
          ? "hfn-special-discount"
          : "hfn-non-early-bird";
      const categoryVal =
        data.overnight_accommodation == "Y" ? "with-dorm" : "without-dorm";
      const accomodation_price = data.overnight_accommodation == "Y" ? 500 : 0;
      let concatValues = "";
      if (v1 <= 4) {
        concatValues = [v2].join("-");
      } else {
        concatValues = [v2].join("-");
      }

      let removeData = "";

      if (config.environment === "staging") {
        removeData = skuList && skuList.filter((e) => e.code == concatValues);
      } else {
        removeData = skuList && skuList.filter((e) => e.code == concatValues);
      }
      if (JSON.parse(localStorage.getItem("list"))) {
        const applyData = JSON.parse(localStorage.getItem("list"));
        /*datass["discount_code"] =applyData[0].discount_code ;
        datass["discount_name"]=applyData[0].discount_name;
        datass["discount_price"] =applyData[0].discount_price;*/
      }
      const newData = {
        ...datass,
        dob: dateconvert(datass.dob),
        race_category_id: v1,
        t_shirt_size: v1 <= 4 || v1 == 7 ? data.t_shirt_size : "",
        overnight_accommodation: v1 <= 4 ? data.overnight_accommodation : "",
        bib_collection_location: v1 <= 4 ? data.bib_collection_location : "",
        sku_id: removeData[0].id,
        race_km: v2,
        sku_title: removeData[0].title,
        race_price:
          Math.round(
            removeData[0].rate +
              (removeData[0].gstPercentage / 100) * removeData[0].rate
          ) + accomodation_price,
        race_category_name: v3,
        secretId: Math.floor(Math.random() * Date.now()),
        discount_price: 0,
        final_amount:
          Math.round(
            removeData[0].rate +
              (removeData[0].gstPercentage / 100) * removeData[0].rate
          ) + accomodation_price,
        discount_code: "",
        discount_name: "",
      };
      if (isEdit === false) {
        const companyDetails = lStorage.get("companyCodeDetails");
        await prevData.push(newData);
        lStorage.set("list", newData);
        await setFormData(newData);
        // setButtonDisable(true);
        const payLoad = await {
          invoicedetails: [
            {
              invoice_name: data.first_name,

              invoice_emailid: data.email_address,

              invoice_address: data.address,
            },
          ],
          participants: prevData,
        };

        await kanhaApi
          .postCompanyRegistration(payLoad, companyDetails?.[0]?.company_code)
          .then((response) => {
            if (
              response.data.isError === false ||
              response.data.statusCode === "200"
            ) {
              if (response.data.isError === false) {
                toast.success("Registration successfully");
                setIsParticipated(true);
                getPartiipantDetails();
                reset();
              }
            } else {
              if (response.data.isError === true) {
                toast.error(response.data.message);
              }
            }
          })
          .catch(function (error) {
            toast.error(error);
            logOut();
          });
      }
    } else {
      if (mobileValid === "mobile number same") {
        setSameNumber(true);
        window.scrollTo(0, 0);
      } else {
        setRaceError(true);
        setFocus("race_category_id");
      }
    }
  };

  const getraceList = () => {
    kanhaApi
      .getracecategory()
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            const raceLists = response?.data?.data?.slice(0, 4);
            setRaceList(raceLists);
          }
        }
      })
      .catch(function (error) {
        toast.error("Invalid Token");
        logOut();
      });
  };

  const raceListsss = () => {
    kanhaApi
      .raceList()
      .then((response) => {
        if (response.status == "200") {
          lStorage.set("skuData", response.data.sku);
        }
      })
      .catch(function (error) {
        toast.error("something went wrong");
        logOut();
      });
  };
  const logOut = () => {
    lStorage.remove("userInfo");
    lStorage.remove("verification_id");
    lStorage.remove("list");
    lStorage.remove("globalRun");
    lStorage.remove("appliedCouponCode");
    navigate("/");
  };

  useEffect(() => {
    const companyDetails = lStorage.get("companyRegData");
    setTimeout(() => {
      editData(companyDetails?.[0]);
    }, 1000);
  }, []);

  const editData = async (data) => {
    setCountryCode(data?.countrycode);
    setShowBib(data?.race_km);

    const fields = [
      "first_name",
      "last_name",
      "email_address",
      "contact_number",
      "gender",
      "blood_group",
      "eContact",
      "emergence_contact_number",
      "address",
      "name_on_bib",
      "t_shirt_size",
      "bib_collection_location",
      "overnight_accommodation",
      "sku_id",
      "race_km",
      "sku_title",
      "race_price",
      "race_category_name",
      "pincode",
      "race_category_id",
      // "organisation_name",
      "gift_a_tree_count",
    ];
    fields.forEach(
      (field) => setValue(field, data?.[field]),
      setValue(
        "dob",
        data?.dob && data?.dob ? datecalendarconvert(data?.dob) : ""
      )
      // setValue(
      //   "organisation_name",
      //   data?.company_name && data?.company_name ? company_name : ""
      // )
    );

    setTimeout(() => {
      setValue(
        "race_category_id",
        data?.race_category_id +
          "," +
          data?.race_km +
          "," +
          data?.race_category_name
      );
      setValue("contact_number", data?.contact_number);
    }, 1100);

    setAccommidationStatus(data?.overnight_accommodation);
  };

  useEffect(() => {
    getraceList();
    raceListsss();
  }, [formData]);

  const validatePhoneNumber = (phoneNumber) => {
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
  };

  const getPartiipantDetails = () =>{
    const companyDetails = lStorage.get("companyCodeDetails");
    if (companyDetails) {
      if (companyDetails?.[0]?.company_code) {
        const companyCode = companyDetails?.[0]?.company_code;
        kanhaApi
          .checkCompanyDetails(companyCode)
          .then((response) => {
            if (
              response.data.isError === false ||
              response.data.statusCode === "200"
            ) {
              setParticipantData(response.data.data);
            }
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    }
  }

  useEffect(() => {
    getPartiipantDetails();
  }, []);

  return (
    <>
      <Breadcrumbsbanner />
      {participantData && participantData?.length === 0 && !isParticipated && (
        <div
          className="Basic-form-wrapper inner-pages p-4"
          style={{ marginBottom: "120px" }}
        >
          <div className="container-fluid">
            <div className="col-md-12">
              <div className="row">
                <div>
                  <h3> Basic Details</h3>
                </div>
                <div className="col-md-9">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="first_name">
                            First Name <span className="requiredColor">*</span>
                          </label>
                          <input
                            {...register("first_name", {
                              required: true,
                              pattern: /^\S[a-zA-Z .]*$/,
                              maxLength: 120,
                            })}
                            autoComplete="new-password"
                            className="form-control"
                          />
                          <span className="error_validation ml-3">
                            {errors.first_name?.type === "required" &&
                              "This field is required."}
                            {errors.first_name &&
                              errors.first_name.type === "maxLength" && (
                                <span>Max length exceeded</span>
                              )}
                            {errors.first_name &&
                              errors.first_name.type === "pattern" && (
                                <span>Please enter valid name</span>
                              )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="last_name">
                            Last Name <span className="requiredColor">*</span>
                          </label>
                          <input
                            {...register("last_name", {
                              required: true,
                              pattern: /^\S[a-zA-Z .]*$/,
                              maxLength: 120,
                            })}
                            autoComplete="new-password"
                            className="form-control"
                          />
                          <span className="error_validation ml-3">
                            {errors.last_name?.type === "required" &&
                              "This field is required."}
                            {errors.last_name &&
                              errors.last_name.type === "maxLength" && (
                                <span>Max length exceeded</span>
                              )}
                            {errors.last_name &&
                              errors.last_name.type === "pattern" && (
                                <span>Please enter valid name</span>
                              )}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="email_address" className="form-label">
                            Email Address
                            <span className="requiredColor">*</span>
                          </label>
                          <input
                            type="email_address"
                            className="form-control"
                            name="email_address"
                            id="email_address"
                            autoComplete="new-password"
                            {...register("email_address", {
                              required: true,
                              pattern: {
                                value:
                                  /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!test|testing)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/,
                                message: "Please enter a valid email",
                              },
                            })}
                            onChange={() => {
                              clearErrors("email_address");
                            }}
                          />
                          <span className="error_validation ml-3">
                            {errors.email_address?.type === "required" &&
                              "This field is required."}
                            {errors.email_address?.message}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            htmlFor="contact_number"
                            className="form-label"
                          >
                            Contact No<span className="requiredColor">*</span>
                          </label>

                          <Controller
                            className=""
                            control={control}
                            name="contact_number"
                            rules={{
                              required: true,
                            }}
                            render={(props) => {
                              return (
                                <PhoneInput
                                  country={"in"}
                                  value={props.field.value}
                                  name={props.field.name}
                                  onChange={(
                                    value,
                                    country,
                                    e,
                                    formattedValue
                                  ) => {
                                    if (countryCode != country?.dialCode) {
                                      setValue("race_category_id", "");
                                    }
                                    setCountryCode(country?.dialCode);
                                    props.field.onChange(formattedValue);
                                    setSameNumber(false);
                                    clearErrors("contact_number");
                                    setIsContactValid(
                                      validatePhoneNumber(
                                        formattedValue?.replace(/[- ]/g, "")
                                      )
                                    );
                                  }}
                                  inputRef={props.field.ref}
                                  enableSearch={true}
                                  className="phoneInput"
                                />
                              );
                            }}
                          />

                          <span className="error_validation ml-3">
                            {errors.contact_number?.type === "required" &&
                              "This field is required."}
                            {errors.contact_number?.message}
                          </span>
                          <span className="error_validation ml-3">
                            {isContactValid === false &&
                              errors.contact_number?.type !== "required" &&
                              "Invalid Phone Number."}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            htmlFor="race_category_id"
                            className="form-label"
                          >
                            Race Category
                            <span className="requiredColor">*</span>
                          </label>
                          <select
                            name="race_category_id"
                            className="form-control"
                            {...register("race_category_id", {
                              required: true,
                            })}
                            autoComplete="new-password"
                            onChange={(e) => {
                              setRaceError(false);
                              clearErrors("race_category_id");
                              setShowBib(e?.target?.value?.split?.(",")?.[1]);
                              const raceCategoryVal =
                                e?.target?.value?.split?.(",")?.[1];
                              if (raceCategoryVal?.includes("virtual")) {
                                setValue("blood_group", "");
                                setValue("eContact", "");
                                setValue("emergence_contact_number", "");
                                setValue("bib_collection_location", "");
                                setValue("overnight_accommodation", "");
                              }
                            }}
                          >
                            <option value="">Select</option>
                            {raceList &&
                              raceList?.map((item, index) => {
                                return (
                                  <option
                                    key={index + 1}
                                    value={[
                                      item?.race_category_id,
                                      item?.race_slug,
                                      item?.race_name,
                                    ]}
                                  >
                                    {item?.title}
                                  </option>
                                );
                              })}
                          </select>
                          <span className="error_validation ml-3">
                            {errors.race_category_id?.type === "required" &&
                              "This field is required."}
                          </span>
                          <span className="error_validation ml-3">
                            {raceError &&
                              "Your age  is not eligible for participant in this selected Race"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="gender" className="form-label">
                            Gender<span className="requiredColor">*</span>
                          </label>
                          <select
                            name="gender"
                            className="form-control"
                            {...register("gender", { required: true })}
                          >
                            <option value="">Select</option>
                            {genderList.map((item, index) => {
                              return (
                                <option key={index + 1} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <span className="error_validation ml-3">
                            {errors.gender?.type === "required" &&
                              "This field is required."}
                          </span>
                        </div>
                      </div>

                      {!showBib?.includes("virtual") && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="blood_group" className="form-label">
                              Blood Group
                              <span className="requiredColor">*</span>
                            </label>
                            <select
                              name="blood_group"
                              className="form-control"
                              {...register("blood_group", { required: true })}
                            >
                              <option value="">Select</option>
                              {bloodGroup.map((item, index) => {
                                return (
                                  <option key={index + 1} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="error_validation ml-3">
                              {errors.blood_group?.type === "required" &&
                                "This field is required."}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="dob" className="form-label">
                            Date of Birth{" "}
                            <span className="requiredColor">*</span>
                          </label>
                          <div>
                            <input
                              type="date"
                              {...register("dob", {
                                required: true,
                              })}
                              max={datePickerId}
                              min={minDateValue}
                              autoComplete="new-password"
                              className="form-control"
                              onChange={() => {
                                setRaceError(false);
                                clearErrors("dob");
                              }}
                            />
                          </div>
                          <span className="error_validation ml-3">
                            {errors.dob?.type === "required" &&
                              "This field is required."}
                          </span>
                        </div>
                      </div>
                      {!showBib?.includes("virtual") && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="eContact">
                              Emergency Contact Name{" "}
                              <span className="requiredColor">*</span>
                            </label>
                            <input
                              {...register("eContact", {
                                required: true,
                                pattern: /^\S[a-zA-Z ]*$/,
                                maxLength: 120,
                              })}
                              autoComplete="new-password"
                              className="form-control"
                            />
                            <span className="error_validation ml-3">
                              {errors.eContact?.type === "required" &&
                                "This field is required."}
                              {errors.eContact &&
                                errors.eContact.type === "maxLength" && (
                                  <span>Max length exceeded</span>
                                )}
                              {errors.eContact &&
                                errors.eContact.type === "pattern" && (
                                  <span>Please enter valid name</span>
                                )}
                            </span>
                          </div>
                        </div>
                      )}
                      {!showBib?.includes("virtual") && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergence_contact_number"
                            >
                              Emergency Contact Number{" "}
                              <span className="requiredColor">*</span>
                            </label>
                            <Controller
                              className="form-control"
                              control={control}
                              name="emergence_contact_number"
                              rules={{
                                required: true,
                              }}
                              render={(props) => {
                                return (
                                  <PhoneInput
                                    country={"in"}
                                    value={props.field.value}
                                    name={props.field.name}
                                    onChange={(
                                      value,
                                      country,
                                      e,
                                      formattedValue
                                    ) => {
                                      setEmCountryCode(country?.dialCode);
                                      props.field.onChange(formattedValue);
                                      setSameNumber(false);
                                      clearErrors("emergence_contact_number");
                                      setIsEcontactValid(
                                        validatePhoneNumber(
                                          formattedValue?.replace(/[- ]/g, "")
                                        )
                                      );
                                    }}
                                    inputRef={props.field.ref}
                                    enableSearch={true}
                                    className="phoneInput"
                                  />
                                );
                              }}
                            />
                            <span className="error_validation ml-3">
                              {errors.emergence_contact_number?.type ===
                                "required" && "This field is required."}
                              {errors.emergence_contact_number?.message}
                            </span>
                            <span className="error_validation ml-3">
                              {isEcontactValid === false &&
                                errors.emergence_contact_number?.type !==
                                  "required" &&
                                "Invalid Phone Number."}
                            </span>
                            <span className="error_validation ml-3">
                              {sameNumberError &&
                                "Contact number and Emergency contact number should not be same"}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label mrtop10"
                            htmlFor="organisation_name"
                          >
                            Organisation / Club{" "}
                          </label>
                          <input
                            {...register("organisation_name", {
                              // required: true,
                              pattern: /^\S[a-zA-Z0-9\.@ ]*$/,
                              maxLength: 120,
                            })}
                            autoComplete="new-password"
                            className="form-control"
                          />
                          <span className="error_validation ml-3">
                            {errors.organisation_name?.type === "required" &&
                              "This field is required."}
                            {errors.organisation_name &&
                              errors.organisation_name.type === "maxLength" && (
                                <span>Max length exceeded</span>
                              )}
                            {errors.organisation_name &&
                              errors.organisation_name.type === "pattern" && (
                                <span>
                                  Please enter valid Organisation / Club name
                                </span>
                              )}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="pincode">
                            Pincode
                            <span className="requiredColor">*</span>
                          </label>
                          <input
                            {...register("pincode", {
                              required: true,
                              pattern: /^[0-9]*$/,
                              maxLength: 10,
                              minLength: 4,
                            })}
                            autoComplete="new-password"
                            className="form-control"
                          />
                          <span className="error_validation ml-3">
                            {errors.pincode?.type === "required" &&
                              "This field is required."}
                            {errors.pincode &&
                              errors.pincode.type === "maxLength" && (
                                <span>Max length exceeded</span>
                              )}
                            {errors.pincode &&
                              errors.pincode.type === "minLength" && (
                                <span>Please enter valid pincode</span>
                              )}
                            {errors.pincode &&
                              errors.pincode.type === "pattern" && (
                                <span>Please enter numeric</span>
                              )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="address" className="form-label">
                            Address<span className="requiredColor">*</span>
                          </label>
                          <textarea
                            className="form-control address Current_Address"
                            name="address"
                            {...register("address", {
                              required: true,
                              pattern: /^[A-Za-z0-9\s\/? .,_-]+$/,
                              maxLength: 120,
                            })}
                            autoComplete="new-password"
                          />
                          <span className="error_validation ml-3">
                            {errors.address?.type === "required" &&
                              "This field is required."}
                            {errors.address &&
                              errors.address.type === "maxLength" && (
                                <span>Max length 120 characters allowed</span>
                              )}
                            {errors.address &&
                              errors.address.type === "pattern" && (
                                <span>Please enter only alpha numeric</span>
                              )}
                          </span>
                          <p className="note_para">
                            International participants are requested to enter
                            their stay address in India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="mt-3"> BIB Details </h3>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="t_shirt_size"
                            >
                              T-Shirt Size{" "}
                              <span className="requiredColor">*</span>
                            </label>
                            <select
                              name="t_shirt_size"
                              className="form-control"
                              {...register("t_shirt_size", {
                                required: true,
                              })}
                            >
                              <option value="">Select</option>
                              {tShirtSizes.map((item, index) => {
                                return (
                                  <option key={index + 1} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="error_validation ml-3">
                              {errors.t_shirt_size?.type === "required" &&
                                "This field is required."}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="bib_collection_location"
                              className="form-label"
                            >
                              BIB Collection Location
                              <span className="requiredColor">*</span>
                            </label>
                            <select
                              name="bib_collection_location"
                              className="form-control"
                              {...register("bib_collection_location", {
                                required: true,
                              })}
                            >
                              <option value="">Select</option>
                              {bibLocation.map((item, index) => {
                                return (
                                  <option key={index + 1} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.bib_collection_location?.type ===
                              "required" && (
                              <span className="error_validation ml-3">
                                {errors.bib_collection_location?.type ===
                                  "required" && "This field is required."}
                                <br />
                              </span>
                            )}
                            <p className="mb-0 note_para">
                              1. BIB Will Not Be Distributed on the Race Day.
                            </p>
                            <p className="mb-0 note_para">
                              2. Please Collect It on the BIB Distribution Day.
                            </p>
                            <p className="note_para">
                              3. Please provide your unique runner ID during BIB
                              collection.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="overnight_accommodation"
                              className="form-label"
                            >
                              Overnight Accommodation (Rs. 500 will be charged)
                              <span className="requiredColor">*</span>
                            </label>
                            <select
                              name="overnight_accommodation"
                              className="form-control"
                              {...register("overnight_accommodation", {
                                required: true,
                              })}
                              onChange={(e) => {
                                setAccommidationStatus(e.target.value);
                                setValue(
                                  "overnight_accommodation",
                                  e.target.value,
                                  true
                                );
                                clearErrors("overnight_accommodation");
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </select>
                            <span className="error_validation ml-3">
                              {errors.overnight_accommodation?.type ===
                                "required" && "This field is required."}
                            </span>
                          </div>
                          <br />
                          <div className="">
                            {accommidationStatus === "Y" && (
                              <>
                                <label className="form-label">
                                  Accommodation Images
                                </label>
                                <br />
                                <SimpleReactLightbox className="test">
                                  <SRLWrapper>
                                    {accommodation &&
                                      accommodation.map((data, index) => {
                                        return (
                                          <div
                                            className="recent-plantation-section-plantation"
                                            key={index + 1}
                                          >
                                            <div
                                              className="img-wrapper img_wrp hand-pointer"
                                              key={data}
                                            >
                                              <img
                                                src={data}
                                                className="img-fluid image-pad-4"
                                                alt=""
                                                width="125px"
                                                height="70px"
                                              />
                                              <div className="overlay overLay-img">
                                                <i className="icon icon-arrows-fullscreen"></i>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </SRLWrapper>
                                </SimpleReactLightbox>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ float: "right" }} className="form-label">
                        &nbsp;
                        <div>
                          <button
                            type="button"
                            className=" btn btn-secondary btn-block"
                            value="Clear"
                            onClick={() => {
                              reset();
                              setAccommidationStatus("");
                              setIsEdit(false);
                            }}
                          >
                            <span>Clear</span>
                          </button>
                          &nbsp;&nbsp;
                          <input
                            type="submit"
                            className="btn btn-primary btn-block"
                            value={"Submit"}
                            disabled={
                              isContactValid === false ||
                              (isEcontactValid === false && true)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {participantData && participantData?.length > 0 && (
        <div
          className="Basic-form-wrapper inner-pages p-4"
        >
          <div className="container-fluid">
            <div className="col-md-12">
              <div className="row">
                <div className="mb-4">
                  <h3 > <b>My Registration</b></h3>
                </div>
                <div className="row">
                  {participantData &&
                    participantData?.map((data, index) => {
                      return (
                        <div className="col-md-3 col-sm-6" key={index + 1}>
                          <div className="card succesful-cards-wrapper first-upper">
                            <Card>
                              <Card.Body>
                                <div className="name-wrapper-item">
                                  <span>
                                    <img
                                      id="preview"
                                      className="image-fluid"
                                      width="35px"
                                      src={CreateImageFromInitials(
                                        500,
                                        data.first_name,
                                        GetRandomColor()
                                      )}
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <ul style={{ paddingTop: "10px", paddingLeft:"0rem" }}>
                                  <li style={{listStyleType:"none"}} className="py-1">
                                    <b>Name:</b> {data.first_name}{" "}
                                    {data.last_name}
                                  </li>
                                  <li style={{listStyleType:"none"}} className="py-1">
                                    <b>Gender:</b> {data.gender}
                                  </li>
                                  {data?.blood_group != "" && (
                                    <>
                                      {data?.race_km?.includes(
                                        "virtual-run"
                                      ) === false && (
                                        <>
                                          <li style={{listStyleType:"none"}} className="py-1">
                                            <b>Blood group:</b>{" "}
                                            {data.blood_group}
                                          </li>
                                        </>
                                      )}
                                    </>
                                  )}
                                  <li style={{listStyleType:"none"}} className="py-1">
                                    <b>DOB:</b>{" "}
                                    {moment(data && data.dob).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </li>

                                  {data?.t_shirt_size != "" && (
                                    <li style={{listStyleType:"none"}} className="py-1">
                                      <b>T-shirt Size:</b> {data.t_shirt_size}
                                    </li>
                                  )}
                                  <li style={{listStyleType:"none"}} className="py-1">
                                    <span className="pr-1">
                                      <img
                                        className="img-fluid"
                                        src={phonesmallicon}
                                        alt="Clock logo"
                                      />
                                    </span>
                                    {data.contact_number}
                                  </li>
                                  <li style={{ textTransform: "none", listStyleType:"none" }}>
                                    <span className="pr-1">
                                      <img
                                        className="img-fluid"
                                        src={envelope}
                                        alt="Clock logo"
                                      />
                                    </span>
                                    {data.email_address}
                                  </li>
                                  {/* <li style={{listStyleType:"none"}} className="py-1">
                                    <span>
                                      <img
                                        className="img-fluid"
                                        src={mapsmallicon}
                                        alt="Clock logo"
                                      />
                                    </span>
                                    {data.address}
                                  </li> */}
                                  {data?.overnight_accommodation != "" && (
                                    <li style={{listStyleType:"none"}} className="py-1">
                                      <b>Accommodation</b> :{" "}
                                      {data.overnight_accommodation === "Y"
                                        ? "Yes"
                                        : "No"}
                                    </li>
                                  )}
                                  {data?.bib_collection_location != "" && (
                                    <li style={{listStyleType:"none"}} className="py-1">
                                      <b>BIB collection location</b> :{" "}
                                      {data.bib_collection_location}
                                    </li>
                                  )}
                                  <li style={{listStyleType:"none"}} className="py-1">
                                    <b>Race Category</b> :{" "}
                                    {data.race_category_name}
                                  </li>
                                </ul>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {isParticipated && (
        <div
          className="Basic-form-wrapper inner-pages p-4"
          style={{ marginBottom: "120px" }}
        >
          <p>Registration Succesfull</p>
        </div>
      )} */}
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
}
